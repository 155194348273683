.section-yt-container {
  position: relative;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.elements-container {
  margin-top: 100px;
  width: 80%;
  /* display: flex; */
  flex-wrap: wrap;
  align-items: center;
}

.elements {
  margin: 0 auto;
  /* width: 90%; */
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  align-items: center;
}

.box-coontainer {
  z-index: 2;
  margin: 30px 35px;
  min-width: 124.28px;
  height: 124.28px;
  left: 693.56px;
  top: 62.55px;
  background: #ffffff;
  border: 1.03567px solid #e7ebee;
  box-shadow: 0px 5.95755px 11.9151px rgba(124, 166, 205, 0.15),
    0px 17.8727px 47.6604px rgba(91, 159, 215, 0.25);
  border-radius: 16.8882px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yt-ellipse {
  position: absolute;
  bottom: -100px;
}

@media (max-width: 480px) {
  .section-yt-container {
    width: 100%;
    padding-top: 60px;
  }
  .elements-container {
    width: 100%;
    overflow-x: auto;
  }
  .elements {
    justify-content: space-between;
  }
  .yt-ellipse {
    display: none;
  }
}
