.email-page-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-data-container {
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid var(--neutral-20);
}

.email-info-container {
  width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--neutral-20);
  border-radius: 20px;
  box-shadow: 0px 3px 4px rgba(112, 128, 141, 0.2),
    0px 0px 3px rgba(112, 128, 141, 0.3);
}

.email-logo {
  width: 160px;
}

@media (max-width: 480px) {
  .email-banner {
    display: none;
  }
  .email-info-container {
    width: 100%;
  }
  .email-data-container {
    border: none;
  }
}
