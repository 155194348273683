.section-six-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9fa;
  width: 95%;
  height: 375px;
  border-radius: 24px;
  margin: 100px auto;
  position: relative;
  padding: 20px 20px 0;
}

.section-six-box {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 30px;
}

.sextion-six-image {
  position: absolute;
  bottom: 0;
  width: 420px;
  z-index: 2;
}

.section-six-ellipse {
  position: absolute;
  left: 0;
  bottom: 0;
}

.section-six-input {
  width: 380px;
  height: 52px;
  border: 1px solid var(--neutral-40);
  border-radius: 4px;
  margin-bottom: 30px;
  padding-left: 10px;
}

@media (max-width: 480px) {
  .section-six-container {
    width: 100%;
    flex-direction: column;
    position: initial;
    height: auto;
  }
  .section-six-box {
    width: 90%;
    margin-bottom: 20px;
    position: relative;
  }
  .section-six-box:first-of-type {
    height: 300px;
  }
  .section-six-input {
    width: 100%;
  }
  .btn-section-six {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .sextion-six-image {
    width: 350px;
  }
  .section-six-ellipse {
    left: -60px;
    transform: rotate(-13deg);
  }
}
