.cta-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* left box  */
.cta-left-box {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cta-info-container {
  width: 70%;
  margin: auto 10% 35% auto;
}

.cta-buttons-container {
  width: 80%;
  /* padding: 0 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* right box  */

.cta-right-box {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-ellipse {
  position: absolute;
  top: -640px;
  left: 50px;
  z-index: -1;
}

@media (max-width: 1280px) {
  .cta-info-container {
    width: auto;
    margin: 0 0 0 15%;
  }
}
@media (max-width: 769px) {
  .cta-container {
    flex-direction: column;
  }
  .cta-left-box {
    width: 100%;
    justify-content: flex-start;
    height: 60%;
    margin-top: 80px;
    padding-right: 32px;
  }
  .cta-info-container {
    margin: 0 0 0 10% !important;
  }
  .cta-right-box {
    width: 100%;
  }
  .cta-ellipse {
    top: -30px;
    left: 400px;
  }
}

@media (max-width: 480px) {
  .cta-buttons-container {
    display: none;
  }
}
