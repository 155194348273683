.cta2-container {
  width: 100%;
}
.cta2-image {
  width: 100%;
  display: block;
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
}
.cta2-data-container {
  margin: 85px auto 100px;
  padding: 0 0 0 50px;
  width: 85%;
  background-color: #f2f8fd;
  border-radius: 24px;
}

.cta2-box {
  position: relative;
}

.cta2-image-ppl {
  position: absolute;
  width: 75%;
  bottom: 0;
}

.arrow {
  transition: 0.25s;
}

.arrow:hover {
  transform: rotate(-45deg);
}

@media (max-width: 480px) {
  .cta2-data-container {
    flex-direction: column-reverse;
    width: 90%;
    padding: 0;
  }
  .cta2-image {
    margin: 0;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
    border-bottom-right-radius: 0;
  }
  .cta2-box {
    margin-bottom: 20px;
  }
}
