.faqs-container {
  position: relative;
  width: 100%;
  padding: 150px 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.faqs {
  margin: 44px auto;
  display: flex;
  padding: 20px 150px;
}

.faq-container {
  margin: 0 30px;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_row-title-text__1MuhU {
  font-weight: bold;
}

@media (max-width: 769px) {
  .faqs {
    padding: 0;
    flex-direction: column;
  }
}
