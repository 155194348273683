.section-five-container {
  width: 95%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  padding: 0 10%;
  background-color: var(--blue-10);
  overflow: hidden;
}

.section-five-box {
  width: 50%;
  position: relative;
}

.section-five-img {
  width: 70%;
  z-index: 2;
}
.section-five-ellipse {
  position: absolute;
  top: 160px;
  left: 190px;
}

@media (max-width: 1280px) {
}
@media (max-width: 768px) {
  .section-five-container {
    height: auto;
    flex-direction: column;
  }
  .section-five-box {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .section-five-img {
    width: 55%;
    margin-top: 35px;
  }
}

@media (max-width: 480px) {
  .section-five-img {
    width: 90%;
  }
}
