.Section-two-container {
  width: 95%;
  padding: 20px;
  background-color: var(--neutral-0);
  display: flex;
  border-radius: 24px;
  justify-content: space-evenly;
  align-items: center;
  margin: 100px auto;
}

.section-two-first-box {
  width: 40%;
  /* margin */
}
.section-two-second-box {
  width: 320px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.section-two-box-icon {
  margin: 20px;
  width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-two-three-box {
  position: relative;
  width: 20%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-two-three-box img {
  width: 450px;
  position: absolute;
  bottom: -155px;
}

.section-two-title-container {
  width: 100%;
  margin: 0 auto auto;
}

.section-two-cards-container {
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto auto 30px;
}
.section-two-card {
  margin: 0 auto;
  padding: 165px 20px 20px;
  height: 465px;
  background-color: #f9f9fa;
  border-radius: 24px;
  position: relative;
}

.first-card {
  width: 326px;
}
.second-card {
  width: 630px;
}

.section-two-card-image {
  display: block;
  margin: 0 auto;
  position: absolute;
  top: -140px;
  left: 55px;
}

.section-two-link {
  position: absolute;
  bottom: 25px;
  left: 20px;
  font-weight: 500;
}
.section-two-arrow {
  position: absolute;
  bottom: 25px;
  right: 50px;
}

@media (max-width: 1280px) {
  .section-two-first-box {
    width: 30%;
    /* margin */
  }
  .section-two-second-box {
    width: 320px;
  }
  .section-two-three-box img {
    width: 400px;
    position: absolute;
    bottom: -155px;
  }
}
@media (max-width: 768px) {
  .Section-two-container {
    flex-direction: column-reverse;
    width: 100%;
    border-radius: 0;
  }
  .section-two-first-box {
    width: 100%;
  }
  .section-two-second-box {
    display: none;
  }
  .section-two-three-box {
    width: 50%;
    flex-direction: column;
  }
  .section-two-three-box img {
    width: 100%;
    position: relative;
    bottom: 0;
  }
}

@media (max-width: 480px) {
  .section-two-three-box {
    width: 90%;
  }
  .Section-two-container {
    margin: 40px auto;
  }
}
