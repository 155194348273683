.Section-three-container {
  width: 80%;
  margin: 0 auto;
}
.section-three-element-container {
  margin: 200px auto;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  width: 95%;
  border: 1px solid var(--neutral-40);
}

.section-three-info-box {
  width: 60%;
  /* margin: 0 10%; */
}
.section-three-image-box {
  width: 30%;
}

.section-three-image {
  width: 100%;
  display: block;
  margin: auto;
}

@media (max-width: 769px) {
  .section-three-element-container {
    flex-direction: column;
    width: 95%;
  }
  .section-three-info-box {
    width: 90%;
    margin: 0 auto;
  }
}
@media (max-width: 480px) {
  .section-three-image-box {
    width: 90%;
  }
  .section-three-element-container {
    margin: 40px auto;
  }
}
