.seccion-one-container {
  margin: 0 auto;
  width: 95%;
  /* height: 100vh; */
  background-color: var(--blue-5);
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seccion-one-leftbox {
  padding-top: 48px;

  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.seccion-one-leftbox img {
  width: 50%;
}

.seccion-one-rightbox {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.section-one-text {
  width: 70%;
}

.seccion-one-subtitle {
  display: flex;
  align-items: center;
}
.seccion-one-subtitle img {
  margin-right: 24px;
}

@media (max-width: 1280px) {
  .cta-info-container {
    width: auto;
    margin: 0 0 0 15%;
  }
}
@media (max-width: 769px) {
  .seccion-one-container {
    width: 100%;
    border-radius: 0;
  }
  .seccion-one-rightbox {
    width: 80%;
  }
  .seccion-one-leftbox {
    display: none;
  }
  .section-one-text {
    width: 100%;
  }
}
