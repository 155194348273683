.header-container {
  position: fixed;
  z-index: 10;
  background-color: var(--white-0);
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--neutral-40);
}

.header-links-container {
  margin-left: auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.header-links-container p {
  margin: 0 20px;
}

.header-buttons-container {
  /* width: 375px; */
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu {
  transition: 0.3s;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
}
.open-menu {
  left: 0%;
}
.closed-menu {
  left: -100%;
}

.close-icon {
  margin: 20px 20px 20px auto;
}

.nav-link {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  text-decoration: none;
}
.nav-link:hover {
  text-decoration: underline;
}
.header-logo {
  width: 140px;
}

@media (max-width: 769px) {
  .header-container {
    height: 64px;
  }
  .header-logo {
    width: 140px;
  }
}
