:root {
  /* white */
  --white-0: #ffffff;
  --white-20: #fcfdfb;
  /* neutral */
  --neutral: #f9f9fa;
  --neutral-0: #fafafd;
  --neutral-20: #e7ebee;
  --neutral-40: #c6ced1;
  --neutral-60: #a1abb4;
  --neutral-80: #70808d;
  --neutral-100-dark: #313840;
  /* blue */
  --blue-0: #f6f9fe;
  --blue-5: #f2f8fd;
  --blue-10: #e6f0f8;
  --blue-20: #bfe1fa;
  --blue-40: #85c3f6;
  --blue-60-main: #0c88ed;
  --blue-80: #0d64b5;
  --blue-100: #053f66;
  --blue-number: #31a3f5;
  /* Turquoise */
  --turquoise-20: #c8f3f5;
  --turquoise-60: #52d6dd;
  --turquoise-70: #43afb4;
  --turquoise-80: #286b6e;
  /* Yellow */
  --yellow-30: #fad89b;
  --yellow-60: #f6ba50;
  --yellow-70: #dd9f3a;
  /* Red */
  --red-60-main: #cf545c;
  /* alert */
  --error: #f57a82;
  --success: #80f5af;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  width: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* texts /////////////////////////// */
.heading-large {
  font-weight: bold;
  font-size: 48px;
}
.heading-medium {
  font-weight: bold;
  font-size: 36px;
}
.heading-small {
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}
.heading-xsmall {
  font-weight: bold;
  font-size: 16px;
}
.text-size-large {
  font-size: 20px;
}
.text-size-small {
  font-size: 16px;
}

.bold {
  font-weight: bold;
}

.dec-none {
  text-decoration: none;
  color: var(--neutral-100-dark);
}

@media (max-width: 769px) {
  .heading-large {
    font-size: 40px;
  }
  .text-size-large {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .heading-large {
    font-weight: bold;
    font-size: 32px;
  }
  .heading-medium {
    font-weight: bold;
    font-size: 24px;
  }
  .heading-small {
    font-weight: bold;
    font-size: 17px;
    margin: 0;
  }
  .heading-xsmall {
    font-weight: bold;
    font-size: 14px;
  }
  .text-size-large {
    font-size: 17px;
  }
  .text-size-small {
    font-size: 14px;
  }
}

/* font colors //////////////////////// */
.blue-main {
  color: var(--blue-60-main);
}
.blue-40 {
  color: var(--blue-40);
}
.blue-100 {
  color: var(--blue-100);
}
.blue-80 {
  color: var(--blue-80);
}
.neutral-60 {
  color: var(--neutral-60);
}
.neutral-80 {
  color: var(--neutral-80);
}
.neutral-dark {
  color: var(--neutral-100-dark);
}

/* alignement ///////////////////////// */
.text-center {
  text-align: center;
}
.w-100 {
  width: 100%;
}
.w-90 {
  width: 90%;
}
.w-xl {
  width: 70%;
}
.w-l {
  width: 60%;
}
.w-m {
  width: 50%;
}
.w-s {
  width: 40%;
}

.h-100 {
  height: 100%;
}
.h-90 {
  height: 90%;
}

@media (max-width: 1280px) {
  .w-l {
    width: auto;
  }
  .w-m {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .w-l {
    width: auto;
  }
  .w-xl {
    width: 90%;
  }
  .w-m {
    width: 80%;
  }
}

/* margins //////////////////////////// */
.m-0 {
  margin: 0;
}
.m-m-0 {
  margin: 8px 0;
}
.m-0-auto {
  margin: 0 auto;
}
.m-auto-0 {
  margin: auto 0;
}

.mt-auto {
  margin-top: auto;
}

.mt-l {
  margin-top: 50px;
}
.mt-m {
  margin-top: 25px;
}
.mt-0 {
  margin-top: 0;
}
/* bottom  */
.mb-0 {
  margin-bottom: 0;
}
.mb-l {
  margin-bottom: 50px;
}
.mb-m {
  margin-bottom: 25px;
}
/* right  */
.mr-s {
  margin-right: 10px;
}
.mr-m {
  margin-right: 15px;
}
.mr-l {
  margin-right: 20px;
}
/* left  */
.ml-s {
  margin-left: 10px;
}
.ml-m {
  margin-left: 15px;
}
.ml-l {
  margin-left: 20px;
}
.ml-xl {
  margin-left: 40px;
}
.ml-auto {
  margin-left: auto;
}

.flex {
  display: flex;
  align-items: center;
}
.align-start {
  align-items: flex-start;
}

.jc-center {
  justify-content: center;
}

.flex-col-center-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

/* global styles ////////////////////// */
.btn-primary {
  border: none;
  transition: 0.25s;
  padding: 12px 32px;
  border-radius: 4px;
  background-color: var(--blue-60-main);
  color: var(--white-0);
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.btn-disabled {
  border: none;
  transition: 0.25s;
  padding: 12px 32px;
  border-radius: 4px;
  background-color: var(--neutral-20);
  color: var(--white-0);
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.btn-primary:hover {
  box-shadow: 0px 4px 6px 0px rgba(133, 195, 246, 0.5);
  background: #31a3f5;
}

.btn-large {
  padding: 12px 102px;
}

.btn-secondary {
  transition: 0.25s;
  padding: 12px 32px;
  border-radius: 4px;
  background-color: var(--white-0);
  border: 1px solid var(--blue-60-main);
  color: var(--blue-60-main);
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.btn-secondary:hover {
  background-color: var(--blue-10);
}

.pointer {
  cursor: pointer;
}
