.footer {
  margin: 0 auto 35px;
  width: 95%;
  padding: 50px;
  background-color: #f9f9fa;
  border-radius: 45px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.footer-box1 {
  width: 25%;
}

.footer-box2 {
  width: 70%;
  margin: 0 0 0 auto;
}

.footer-box2-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer-box2-container-contact {
  display: flex;
}

.footer-contact {
  width: 33%;
}

.footer-contact-info-container {
  display: flex;
  margin: 30px 0;
}

.nav-link-footer {
  text-decoration: none;
}
.nav-link-footer:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 480px) {
  .footer {
    width: 100%;
    padding: 10px 32px;
    border-radius: 0;
    flex-direction: column;
    margin: 0;
  }
  .footer-box1,
  .footer-box2 {
    width: 100%;
  }
  .title-footer {
    width: 100%;
    margin: 20px 0 0;
    font-size: 24px;
  }
  .footer-box1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer-box2-container-contact {
    flex-direction: column;
  }
}
