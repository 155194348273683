.ayn-section-container {
  width: 95%;
  margin: 100px auto;
}

.css-heg063-MuiTabs-flexContainer {
  justify-content: center;
  font-size: 20px !important;
}

.ayn-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* height: 50%; */
  padding: 5% 0;
  margin: auto 0;
}

.ayn-info-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
  width: 30%;
  height: 140px;
}

.ayn-info-container div {
}

.ayn-info-container img {
  margin-right: 15px;
}

@media (max-width: 768px) {
  .ayn-info-container {
    width: 100%;
    justify-content: flex-start;
  }
}
